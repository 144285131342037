/* scrollbar customization */

::-webkit-scrollbar{
  width: 17px;
}

::-webkit-scrollbar-track{
  background-color: #111111;
}

::-webkit-scrollbar-thumb{
  background-color: gray;
  border: 1px solid #111111;
  border-radius: 50px;
  transition: all 1s ease-in-out;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #158cba;
}