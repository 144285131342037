#logoOne{
    animation: rot 7s linear infinite;
}

#logoOne:hover{
    animation: none;
}

#dark{
    background-color: #4F0341;
}

/* padding: 0 20px; */
#achorTag{
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(210, 203, 203);
    transition: 0.3s ease-in-out;
}

#achorTag:hover{
    color: white;
    text-decoration: underline solid white 2px;
    text-underline-offset: 5px;
}

.toggleDark{
    background-color: #2f3542;
}

.offcan{
    font-size: 15px;
}

@keyframes rot{
    33.33%{
        rotate: 120deg;
    }
    66.66%{
        rotate: 240deg;
    }
    100%{
        rotate: 360deg;
    }
}

@media (max-width: 767px) {
    #achorTag{
        border-top: 1px solid black;
        border-bottom: 1px solid rgb(172, 162, 162);
        width: 100%;
        padding: 15px;
        transition: all 1s ease-in-out;
    }
    #achorTag:hover{
        text-decoration: none;
        margin-left: 30px;
    }
}

@media (max-width: 347px){
    .offcan{
        font-size: 12px;
    }
}